<template>
  <component :is="component">
    <slot :collection="dataSource" :model="model" :schema="schema" :schemas="schemas" :count="count"></slot>
  </component>
</template>

<script>
import qs from 'qs'
export default {
  props: {
    name: {
      type: String
    },
    component: {
      type: [String, Object],
      default: 'div'
    },
    query: {
      type: Object
    },
    filter: {
      type: Object
    },
    transform: {
      type: Function
    },
    limit: {
      type: Number
    },
    page: {
      type: Number
    },
    schemaMode: {
      type: [Boolean, String],
      default: false
    },
    dataset: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      collection: [],
      schema: null,
      model: {},
      count: 0,
      status: 0,
      loading: false
    }
  },
  computed: {
    dataSource () {
      const { collection, filter } = this
      return filter ? collection.filter(item => {
        return Object.keys(filter).every(key => filter[key] === item[key])
      }) : collection
    },
    schemas () {
      const list = this.schema ? this.schema.model.attrs.filter(a => a.list || (a.dataType === 'wordbook' && a.typeObject)) : []
      const schemas = list.map(l => {
        if (l.list) {
          return [l.name, l.list.split(',').map(i => {
            const [key, value] = i.split(':')
            return value ? { [key]: value } : key
          })]
        } else {
          return [l.name, (l.typeObject.data && l.typeObject.data.collection) || []]
        }
      })
      return Object.fromEntries(schemas)
    }
  },
  methods: {
    async load () {
      const { name, query, limit = 0, page, dataset } = this
      this.collection = []
      this.model = {}
      // this.count = 0
      this.status = 0
      this.loading = true
      const type = dataset ? 'dataset' : 'api'
      try {
        const { data: { collection, count, model, data }, status } = await this.$api.get(`/${type}/${name}?${qs.stringify({ ...query, _size: limit, $limit: page })}`)
        this.collection = collection || data
        this.model = model
        this.count = count
        this.status = status
      } catch (error) {
        if (!error.response) {
          this.status = -1
        } else {
          const { status } = error.response
          this.status = status
        }
        return error
      } finally {
        this.loading = false
      }
    },
    async loadSchema () {
      const { name } = this
      try {
        const { data } = await this.$api.get(`/schemas/${name}`)
        this.schema = data
      } catch (error) {
        if (!error.response) {
          this.status = -1
        } else {
          const { status } = error.response
          this.status = status
        }
        return error
      } finally {
        this.loading = false
      }
    }
  },
  async created () {
    if (this.schemaMode === 'await') {
      await this.loadSchema()
      this.load()
    } else if (this.schemaMode === 'async') {
      this.loadSchema()
      this.load()
    } else if (this.schemaMode) {
      this.loadSchema()
    } else {
      this.load()
    }
  },
  watch: {
    name (name) {
      this.load()
    },
    page () {
      this.load()
    },
    query: {
      handler (newVal, oldVal) {
        if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) this.load()
      },
      deep: true
    }
  }
}
</script>
